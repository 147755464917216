import React from 'react'
import { useRetrieveUser, useRetrieveUserFeatures } from '../service/hooks/auth'

interface IState {
  isVendorManagementEnabled: boolean | undefined
  isLoadingUserFeatures: boolean
}

const initialState: IState = {
  isVendorManagementEnabled: undefined,
  isLoadingUserFeatures: false,
}

export const FeatureFlaggingContext = React.createContext<IState>(initialState)

interface Props {
  children?: React.ReactNode | React.ReactNode[]
}

export const FeatureFlaggingContextProvider: React.FC<Props> = ({ children }) => {
  const { data: userInfo } = useRetrieveUser('me')

  const { data: userFeatures, isLoading: isLoadingUserFeatures } = useRetrieveUserFeatures(
    userInfo?.guid
  )
  const isVendorManagementEnabled = userFeatures?.some(
    (feature) => feature.feature_name === 'VENDOR_MANAGEMENT'
  )

  return (
    <FeatureFlaggingContext.Provider
      value={{
        isVendorManagementEnabled,
        isLoadingUserFeatures,
      }}
    >
      {children}
    </FeatureFlaggingContext.Provider>
  )
}
