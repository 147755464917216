export const box_minimized_style = {
  display: { xs: 'flex', md: 'none' },
}

export const box_maximized_style = {
  display: { xs: 'none', md: 'flex' },
}

export const menu_style = {
  display: { xs: 'block', md: 'none' },
}

export const org_pages = ['Bills', 'Commitments', 'Clients', 'Reports', 'Vendor Management']
export const org_pages_without_vendor_management = ['Bills', 'Commitments', 'Clients', 'Reports']
export const org_redirect = [
  '/billing',
  '/commitments?page=1',
  '/clients',
  '/reports',
  '/parent-vendors',
]
export const client_pages = ['Bills', 'Commitments', 'Reports']
